import React from "react";
import ReactMarkdown from "react-markdown";

const markdownContent = `
# 서비스 이용 약관

## 제 1조 목적

이 약관은 앤픽에 접속과 사용자에 의해서 업로드 및 다운로드 되어 표시되는 모든 정보, 텍스트, 이미지 및 기타 자료를 이용하는 이용자(이하 "회원")와 서비스 이용에 관한 권리 및 의무와 책임사항, 기타 필요한 사항을 규정하는 것을 목적으로 합니다.

## 제 2조 약관의 게시와 효력, 개정

① 회사는 서비스의 가입 과정에 본 약관을 게시합니다.

② 회사는 관련법에 위배되지 않는 범위에서 본 약관을 변경할 수 있으며, 개정 전 약관과 함께 적용일자 7일 전부터 웹사이트에서 확인할 수 있도록 게시합니다. 다만, 이용자에게 불리하게 약관을 변경하는 경우에는 적용일자 30일 전에 개정내용을 이용자가 확인할 수 있도록 게시합니다.

③ 회원은 회사가 전항에 따라 변경하는 약관에 동의하지 않을 권리가 있으며, 이 경우 회원은 회사에서 제공하는 서비스 이용 중단 및 탈퇴 의사를 표시하고 서비스 이용 종료를 요청할 수 있습니다. 다만, 회사가 회원에게 변경된 약관의 내용을 통보하면서 회원에게 "7일 이내 의사 표시를 하지 않을 경우 의사 표시가 표명된 것으로 본다는 뜻"을 명확히 통지하였음에도 불구하고, 거부의 의사표시를 하지 아니한 경우 회원이 변경된 약관에 동의하는 것으로 봅니다.

## 제 3조 약관의 해석과 예외 준칙

① 회사는 제공하는 개별 서비스에 대해서 별도의 이용약관 및 정책을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우 개별 서비스의 이용약관을 우선하여 적용합니다.

② 본 약관에 명시되지 않은 사항이 관계법령에 규정되어 있을 경우에는 그 규정에 따릅니다.

## 제 4조 용어의 정의

① 서비스: 개인용 컴퓨터(PC), TV, 휴대형 단말기, 전기통신설비 등 포함 각종 유무선 장치와 같이 구현되는 단말기와 상관없이 회원이 이용할 수 있는 앤픽 관련 제반 서비스를 의미합니다. 제반 서비스에는 개발자 및 서비스 제공자가 앤픽 Open Application Programming Interface 서비스와 이를 이용하여 개발한 API 응용 애플리케이션 또는 웹서비스도 포함됩니다.

② 회원: 회사와 서비스 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 모든 사용자를 의미합니다. 

③ 아이디: 회원의 식별 및 서비스 이용을 위하여 회원이 선정하고 회사가 부여한 문자 및 숫자의 조합을 의미합니다.

④ 비밀번호: 회원의 개인 정보보호 및 확인을 위해서 회원이 정한 문자 또는 숫자의 조합을 의미합니다.

⑤ 도메인: 회원의 서비스 이용을 위하여 회원이 신청하여 회사가 부여한 고유한 인터넷 주소를 의미합니다. 회사는 제공하는 제반 서비스를 위해서 서비스에 따라 별도의 도메인 주소를 추가적으로 제공합니다.

⑥ 게시물: 회원이 서비스를 이용함에 있어 회원이 서비스에 게시한 문자, 문서, 그림, 음성, 링크, 파일 혹은 이들의 조합으로 이루어진 정보 등 모든 정보나 자료를 의미합니다.

⑦ 닉네임 : 회원이 서비스 내에서 식별되기 위해 사용하는 별칭. 다른 회원들과 구별되는 고유한 이름으로, 계정 생성 시 자동으로 생성되거나 회원이 직접 설정할 수 있습니다.

## 제 5조 이용계약의 체결

① 이용계약은 회원이 앤픽 서비스 및 제반 서비스에서 제공하는 회원 가입 페이지에서 서비스 이용약관에 동의한 후 이용신청을 하고 신청한 내용에 대해서 회사가 승낙함으로써 체결됩니다.

② 회사는 이용약관에 동의한 후 이용신청한 사용자에 대해서 원칙적으로 접수 순서에 따라 서비스 이용을 승낙함을 원칙으로 합니다. 다만 업무 수행상 또는 기술상 지장이 있을 경우 일정시간 가입승인을 유보할 수 있습니다.

③ 회사는 다음 각 호에 해당하는 신청에 대해서 승낙하지 않거나 사후에 이용계약을 해지할 수 있습니다.

- 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
- 제3자의 전자우편 주소를 이용하여 신청한 경우
- 허위의 정보를 기재하거나, 회사가 필수적으로 입력을 요청한 부분을 기재하지 않은 경우
- 부정한 용도로 서비스를 사용하고자 하는 경우
- 이용자의 귀책 사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
- 회사의 정책에 적합하지 않은 회원으로 판단되는 경우 또는 서비스 제공이 곤란한 경우
- 회원의 이용 목적이나 서비스 이용 방법이 회사의 재산권이나 영업권을 침해하거나 침해할 우려가 있는 경우
- 비정상적인 방법을 통하여 아이디 및 도메인을 대량으로 생성하는 경우

④ 회사는 회원에 대해 회사 정책에 따라 등급별로 구분하여 이용 시간, 이용 횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.

⑤ 회원은 회사에 언제든지 회원 탈퇴를 요청하여 이용 계약을 해지할 수 있습니다.

⑥ 회원은 회원 가입 시 기재한 개인정보의 내용에 변경이 발생한 경우, 즉시 변경사항을 정정하여 기재하여야 합니다. 변경의 지체로 인하여 발생한 회원의 손해에 대해 회사는 책임을 지지 않습니다.

⑦ 회사는 관련 법률 및 회사의 개인정보처리방침에서 정한 바에 따라 회원에게 제공을 요청하는 회원정보 및 기타정보 항목을 추가, 삭제 등 변경하여 수집 및 이용할 수 있습니다.

## 제 6조 개인정보보호 의무

① 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.

② 회사는 서비스를 중단하거나 회원이 개인정보 제공 동의를 철회한 경우 신속하게 회원의 개인정보를 파기합니다. 단, 회사는 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법률에 따라 보존의무를 부담하는 경우, 해당 범위에서 개인정보를 보관할 수 있으며 자세한 내용은 개인정보처리방침에서 정하는 바에 의합니다.

③ 회사는 서비스 개선 및 회원 대상 서비스 소개 등의 목적으로 회원의 동의 하에 관계 법령에서 정하는 바에 따라 추가적인 개인정보를 수집할 수 있습니다.

④ 회사는 법률에 특별한 규정이 있는 경우를 제외하고는 회원의 별도 동의 없이 회원의 계정정보를 포함한 일체의 개인정보를 제3자에게 공개하거나 제공하지 아니합니다. 단, 회원 본인의 가족 구성원은 서비스 이용 과정에서 회원 본인의 개인정보를 볼 수 있습니다.

⑤ 회사는 향후 제공하는 서비스에서 회원의 편의를 위해서 회원의 계정 정보를 사용할 수 있도록 링크 및 기타 방법을 제공할 수 있습니다.

## 제 7조 회원의 아이디 및 비밀번호

① 회원은 아이디와 비밀번호에 관해서 관리책임이 있습니다.

② 회원은 아이디 및 비밀번호를 본인과 본인의 가족 구성원을 제외한 제3자가 이용하도록 제공하여서는 안됩니다.

③ 회사는 회원이 아이디 및 비밀번호를 소홀히 관리하여 발생하는 서비스 이용상의 손해 또는 회사의 고의 또는 중대한 과실이 없는 제3자의 부정이용 등으로 인한 손해에 대해 책임을 지지 않습니다.

④ 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.

## 제 8조 닉네임과 관련된 규정

① 자동 닉네임 생성

사용자가 회원가입 또는 소셜 로그인을 통해 처음 서비스를 이용할 때, 소셜 닉네임이 없을 경우 시스템은 자동으로 닉네임을 생성하여 부여합니다. 자동 생성된 닉네임은 기본적으로 ‘더키XXXXX' 형태로 제공되며, 'XXXXX'는 무작위 숫자 조합입니다.

카카오의 경우 닉네임이 수정되지 않습니다.

자동 생성된 닉네임은 서비스 내에서 임시 식별자로 사용되며, 사용자는 이후 언제든지 닉네임을 수정할 수 있습니다.

② 닉네임 수정 가능 여부: 사용자는 자동 생성된 닉네임을 원하는 대로 수정할 수 있습니다.

닉네임 수정은 회원가입 후 또는 소셜 로그인 후 언제든지 계정 설정에서 가능합니다.

닉네임 수정 횟수 제한: 사용자는 닉네임을 무제한으로 수정할 수 있으나, 하루에 한 번만 수정 가능합니다. 이를 통해 악의적인 닉네임 변경을 방지하고, 사용자 간의 혼란을 줄입니다. 닉네임을 수정한 후, 24시간 이내에는 다시 닉네임을 변경할 수 없습니다. 

닉네임 중복 허용 여부: 닉네임은 서비스 내에서 고유해야 합니다. 다른 사용자가 이미 사용 중인 닉네임은 설정할 수 없습니다. 닉네임이 중복될 경우, 사용자는 다른 닉네임을 선택하도록 안내됩니다. 

③ 닉네임 규정: 닉네임은 한글은 2자 이상, 6자 이하, 영어는 2자이상, 8자이하로 설정할 수 있습니다. 닉네임에는 특수 문자(예: !, @, #)를 사용할 수 없으며, 공백 또한 허용되지 않습니다. 닉네임은 욕설, 혐오 표현, 불쾌감을 줄 수 있는 단어 등을 포함할 수 없습니다. 서비스 운영자는 이러한 닉네임을 사전 또는 사후 검토하여 수정 또는 삭제할 수 있는 권한을 가집니다.

비속어 및 금지어 필터링 : 닉네임 설정 시, 금지된 단어(예: 비속어, 차별적 표현 등)가 포함될 경우 시스템이 자동으로 경고 메시지를 표시하고, 해당 닉네임의 사용을 차단합니다. 금지어 목록은 서비스 정책에 따라 주기적으로 업데이트됩니다.

④ 특정 사용자 요청에 따른 닉네임 변경 : 만약 다른 사용자가 사용하는 닉네임이 명예훼손, 사칭, 또는 다른 문제를 일으킬 경우, 서비스 운영자는 해당 사용자의 닉네임을 변경하도록 요청할 수 있습니다. 사용자는 운영자의 요청에 따라 닉네임을 수정해야 하며, 이를 따르지 않을 경우 계정 이용이 제한될 수 있습니다.

⑤ 운영자 권한 및 정책 위반 시 조치

운영자 수정 권한 : 서비스 운영자는 서비스의 질을 유지하기 위해 부적절한 닉네임을 가진 계정을 발견할 경우, 해당 닉네임을 즉시 수정하거나 삭제할 권한을 가집니다.

운영자는 닉네임 관련 정책을 위반한 사용자에게 경고를 발송하며, 경고를 무시한 경우 계정 일시 정지 또는 영구 차단 등의 조치를 취할 수 있습니다.

⑥ 사용자 신고 기능 : 사용자는 부적절한 닉네임을 신고할 수 있는 기능을 제공합니다. 신고된 닉네임은 운영자에 의해 검토되며, 필요 시 수정 또는 삭제 조치가 이루어집니다.

이 정책은 사용자 간의 원활한 소통과 서비스 내에서의 건전한 환경 유지를 목표로 합니다. 서비스 운영자는 언제든지 이 정책을 업데이트할 수 있으며, 중요한 변경 사항이 있을 경우 사용자에게 사전 통지할 것입니다.

## 제 9조 회사의 의무

① 회사는 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여 노력합니다.

② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 현재 인터넷 보안기술의 발전수준 및 회사가 제공하는 서비스의 성격에 적합한 보안시스템을 갖추고 운영해야 합니다.

③ 회사는 서비스를 이용하는 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우를 이를 처리하여야 합니다. 이때 처리과정에 대해서 회원에게 메일 및 게시판 등의 방법으로 전달합니다.

④ 회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.

## 제 10조 회원의 의무

① 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.

② 회원은 회사의 사전 허락 없이 회사가 정한 이용 목적과 방법에 반하여 영업/광고활동 등을 할 수 없고, 회원의 서비스 이용이 회사의 정보자산, 재산권, 영업권 또는 비즈니스 모델을 침해하여서는 안됩니다.

③ 회원은 회사의 명시적 사전 동의가 없는 한 서비스의 이용권한 및 기타 이용계약 상의 지위를 회원 본인의 가족 구성원을 제외한 제3자에게 양도, 증여, 대여할 수 없으며 이를 담보로 제공할 수 없습니다.

④ 회원은 다음 각호에 해당하는 행위를 해서는 안됩니다.

- 이용 신청 또는 회원정보 변경 시 허위내용 등록
- 타인의 정보 도용
- 회사의 운영자, 임직원, 회사를 사칭하거나 관련 정보를 도용
- 회사가 게시한 정보의 변경
- 회사와 기타 제3자의 저작권, 영업비밀, 특허권 등 지적재산권에 대한 침해
- 회사와 다른 회원 및 기타 제3자를 모욕, 비방, 희롱, 위협하거나 명예를 손상시키는 행위
- 외설, 폭력적인 메시지, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위
- 해킹을 통해서 다른 사용자의 정보를 취득하는 행위
- 기타 현행 법령에 위반되는 불법적인 행위

⑤ 회사는 회원의 게시물에 대하여 자동화된 시스템을 통하여 욕설 등을 모니터링하여 이를 비공개처리할 수 있습니다.

⑥ 회사는 회원이 전항에서 금지한 행위를 하는 경우, 위반 행위의 경중에 따라 서비스의 이용을 정지하거나, 이용 계약의 해지, 회원 자격의 상실 등의 조치를 취할 수 있습니다.

## 제 11조 서비스 이용의 중지 및 해지

① 회원은 사이트에서 언제든지 온라인을 통해 회원 탈퇴를 요청할 수 있으며, 사이트는 이와 같은 요청을 받았을 경우 신속하게 처리합니다.

② 회원 가입 시 입력했던 개인신상 정보 및 본인 도메인에 기록된 저작물 일체는 회원 탈퇴 시 삭제됩니다. 단, 저작물이 공동 저작을 통해 작성된 경우에는 공동 저작자의 도메인에 해당 게시물이 남을 수 있고, 제3자에 의하여 보관되거나 무단복제 등을 통하여 복제됨으로써 해당 저작물이 삭제되지 않고 재 게시된 경우에 대하여 사이트는 책임을 지지 않습니다. 또한, 본 약관 및 관계 법령을 위반한 회원의 경우 다른 회원을 보호하고, 법원, 수사기관 또는 관련 기관의 요청에 따른 증거자료로 활용하기 위해 회원탈퇴 후에도 관계 법령이 허용하는 한도에서 회원의 아이디 및 회원정보를 보관할 수 있습니다.

③ 사이트는 회원이 본 약관 제10조의 이용자의 의무를 위반한 경우 및 서비스의 정상적인 운영을 방해한 경우에는 사전 통보 후 회원 자격을 제한, 이용계약을 해지하거나 또는 기간을 정하여 서비스의 이용을 중지할 수 있습니다.

④ 사이트는 전항에도 불구하고, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다.

⑤ 사이트는 회원이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.

⑥ 회원은 본 조에 따른 이용제한 등에 대해 사이트가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 사이트가 인정하는 경우 사이트는 즉시 서비스의 이용을 재개합니다.
`;

const TermsOfService: React.FC = () => {
  return (
    <div className="container mx-auto p-4 mt-20">
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <h1 className="text-4xl font-bold my-4" {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 className="text-3xl font-semibold my-4" {...props} />
          ),
          h3: ({ node, ...props }) => (
            <h3 className="text-2xl font-semibold my-2" {...props} />
          ),
          p: ({ node, ...props }) => (
            <p className="text-base my-2" {...props} />
          ),
          li: ({ node, ...props }) => (
            <li className="list-disc ml-6" {...props} />
          ),
        }}
      >
        {markdownContent}
      </ReactMarkdown>
    </div>
  );
};

export default TermsOfService;
